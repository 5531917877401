export function safariFullscreen(video, onChange) {
  // 进入全屏
  function enterFullscreen() {
    video.webkitEnterFullscreen();
  }

  // 退出全屏
  function exitFullscreen() {
    video.webkitExitFullscreen();
  }
  var onbegin = function onbegin() {
    onChange(true);
  };
  var onExit = function onExit() {
    onChange(false);
  };
  // 监听全屏事件
  video.addEventListener('webkitbeginfullscreen', onbegin);
  video.addEventListener('webkitendfullscreen', onExit);

  // 切换全屏状态
  function toggleFullscreen() {
    if (video.webkitDisplayingFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  }
  return {
    getFullscreen: function getFullscreen() {
      return video.webkitDisplayingFullscreen;
    },
    enterFullscreen: enterFullscreen,
    exitFullscreen: exitFullscreen,
    toggleFullscreen: toggleFullscreen,
    destory: function destory() {
      video.removeEventListener('webkitbeginfullscreen', onbegin);
      video.removeEventListener('webkitendfullscreen', onExit);
    }
  };
}