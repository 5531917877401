import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var NimoRspCode = {
  kMRC_OK: 0,
  kMRC_FAILED: 1,
  kMRC_NOTFOUND: 2,
  kMRC_UNKNOWN: 3,
  kMRC_DB_FAILED: 4,
  kMRC_REDIS_FAILED: 5,
  kMRC_PARAMETER_ERR: 6,
  kMRC_OVERFREQUENCY: 7,
  kMRC_TARGET_NOTFOUND: 8,
  kMRC_LIMIT_NUM: 9,
  kMRC_NO_CONFIG: 10,
  kMRC_FORBIDDEN: 11,
  kMRC_DCACHE_FAILED: 12,
  kMRC_DUPLICATE: 13,
  kMRC_INSUFFICIENT_BALANCE: 14,
  kMRC_PARTIAL_FAILED: 15,
  kMRC_AUTH_ERR: 101,
  kMRC_INVALID_USER: 102,
  kMRC_UDBTOKEN_ERR: 103,
  kMRC_UDBTOKEN_EXPIRED: 104,
  kMRC_AUTH_UDBBANED: 105,
  kMRC_ANONYMOUS_USER: 106,
  kMRC_CANCELLATION_USER: 107,
  kMRC_JAVATOKEN_FAILED: 108,
  kMRC_NO_PERMISSION: 109,
  kMRC_PIC_AUDIT_FAILED: 150,
  kMRC_PIC_AUDIT_IN_REVIEW: 151,
  kMRC_TEXT_AUDIT_ILLEGAL: 152,
  kMRC_RISK_USER: 160,
  kMRC_PRESENTER_AUTH_ERR: 201,
  kMRC_MASTEROFFLINE: 202,
  kMRC_AUTH_FORBID: 203,
  kMRC_PLAYBLACK_FINISHED: 204,
  kMRC_FORBID_AREA: 205,
  kMRC_PRESENTER_ONLINE: 206,
  kMRC_ROOMTYPE_ILLEGAL: 207,
  kMRC_MAX_CHARACTERS: 208,
  kMRC_USER_PROHIBITED_EDIT: 209,
  kMRC_REQ_AUDIT_TEXT_FAILED: 210,
  kMRC_TEXTURL_DECODE_EXCRPT: 211,
  kMRC_TEXTURL_ENCODE_EXCRPT: 212,
  kMRC_REQ_JAVA_FAILED: 213,
  KMRC_ROOM_THEME_EMPTY: 214,
  KMRC_ROOM_NOTEXIST: 215,
  kMRC_SENSITIVE_CONTENT: 216,
  kMRC_PRESENTER_LIVING: 217,
  KMRC_MATCH_FORBID_ROOMTYPE: 218,
  KMRC_MEDIA_SET_ROOM_FAILED: 219,
  kMRC_CHANGE_LIVETYPE_LIMIT: 220,
  kMRC_CAN_NOT_STOP_PAY_LIVE: 221,
  kMRC_CHANGE_LIVETYPE_IN_LINK: 222,
  kMRC_CONTINUE_LIVE: 223,
  kMRC_ROOM_VIEWER_LIMIT: 230,
  kMRC_USER_LEVEL_LIMIT: 231,
  kMRC_MYSTEROR_NOT_OPEN: 232,
  kMRC_PRESENTER_IS_MINOR: 233,
  kMRC_MESSAGE_SENWORD: 300,
  kMRC_MESSAGE_BAN: 301,
  kMRC_MESSAGE_BLAKLIST: 302,
  kMRC_MESSAGE_INVALID_EMOTICON: 303,
  kMRC_MESSAGE_GM_REQUEST: 304,
  kMRC_MESSAGE_FANS_ONLY: 305,
  kMRC_MESSAGE_PAYCHAT_FAIL: 306,
  kMRC_MESSAGE_PAYCHAT_NOTENOUGHMONEY: 307,
  kMRC_MESSAGE_FOLLOWER_ONLY: 308,
  kMRC_MESSAGE_SLOWMODE_LIMIT: 309,
  kMRC_MESSAGE_PAYCHAT_ANCHOR_NOT: 310,
  kMRC_MESSAGE_PAYCHAT_NUM_LIMIT: 311,
  kMRC_MESSAGE_PAYCHAT_OPTION_NOT: 312,
  kMRC_MESSAGE_SAY_HELLO_ALREADY: 313,
  kMRC_MESSAGE_BE_BLOCKED: 314,
  kMRC_MESSAGE_SEND_KISS_ALREADY: 315,
  kMRC_MESSAGE_PAYCHAT_GUARDIAN_DAY_LIMIT: 316,
  kMRC_MESSAGE_SAY_PAT_ALREADY: 317,
  kMRC_MESSAGE_USER_LEVEL_LIMIT: 318,
  kMRC_CHATBOT_COMMAND_EXIST: 380,
  kMRC_CHATBOT_COMMAND_LIMIT_NUM: 381,
  kMRC_CHATBOT_TIMER_LIMIT_NUM: 382,
  kMRC_CHATBOT_FEATURE_LIMIT_NUM: 383,
  kMRC_CHATBOT_ANCHOR_LEVEL_LIMIT: 384,
  kMRC_CHATBOT_MESSAGE_AUDIT_FAIL: 385,
  kMRC_CHATBOT_COMMAND_UNAVAILABLE: 386,
  kMRC_PROP_LIST_OK: 400,
  kMRC_PROP_LIST_EXIST: 401,
  kMRC_PROP_LIST_NOTEXIST: 402,
  kMRC_NOBLE_VALID_DAY_LIMIT: 450,
  kMRC_NOBLE_JOIN_FAIL: 451,
  kMRC_NOBLE_RENEW_FAIL: 452,
  kMRC_NOBLE_NETWORK_ERROR: 453,
  kMRC_NOBLE_BLACK_USER: 454,
  kMRC_NOBLE_PRICE_CHANGE: 455,
  kMRC_PAYRESPCODE_OK: 500,
  kMRC_PAYRESPCODE_CONFIRM: 501,
  kMRC_PAYRESPCODE_FAIL: 502,
  kMRC_PAYRESPCODE_NOTENOUGHMONEY: 503,
  kMRC_PAYRESPCODE_CLOSE: 504,
  kMRC_PAYRESPCODE_PAYINFORETURN: 505,
  kMRC_PAYRESPCODE_INVAILDTYPE: 506,
  kMRC_PAYRESPCODE_OVERLIMIT: 507,
  kMRC_PAYRESPCODE_UDB: 508,
  kMRC_PAYRESPCODE_ANTIBRUSH: 509,
  kMRC_PAYRESPCODE_PARAM: 510,
  kMRC_PAYRESPCODE_ACCOUNTDJ: 511,
  kMRC_PAYRESPCODE_OVERFREQUENCY: 512,
  kMRC_PAYRESPCODE_OVERTOTALLIMIT: 513,
  kMRC_PAYRESPCODE_ORDEREXIST: 514,
  kMRC_PAYRESPCODE_INBACKLIST: 515,
  kMRC_PAYRESPCODE_UNKNOWN: 518,
  kMRC_PAYRESPCODE_SYSTEM_BUSY: 519,
  kMRC_PAYRESPCODE_GENORDERFAIL: 520,
  kMRC_PAYRESPCODE_PROPSTATUS: 521,
  kMRC_PAYRESPCODE_PAYHTTPTIMEOUT: 522,
  kMRC_PAYRESPCODE_ROOMCACHEFAIL: 523,
  kMRC_PAYRESPCODE_BACKPACK_EXPIRED: 524,
  kMRC_PAYRESPCODE_BACKPACK_FAIL: 525,
  kMRC_PAYRESPCODE_BACKPACK_TAKE: 526,
  kMRC_PAYRESPCODE_USERINFO_FAIL: 527,
  kMRC_PAYRESPCODE_CHIP_GIFT: 528,
  kMRC_PAYRESPCODE_ROYAL_GIFT: 529,
  kMRC_PAYRESPCODE_CANNOT_MYSELF: 530,
  kMRC_PAYRESPCODE_CANNOT_THIS_ROOM: 531,
  kMRC_PAYRESPCODE_CHANNEL_WRONG: 532,
  kMRC_PAYRESPCODE_FAMILY_GIFT: 533,
  kMRC_PAYRESPCODE_COIN_DEALER: 534,
  kMRC_PAYRESPCODE_VOICEROOM: 535,
  kMRC_PAYRESPCODE_LUCKY_BAG_GIFT: 536,
  kMRC_PAYRESPCODE_GUARDIAN_GIFT: 537,
  kMRC_PAYRESPCODE_UPGRADE_GIFT: 538,
  kMRC_PAYRESPCODE_SVIP_GIFT: 539,
  kMRC_PAYRESPCODE_NOT_IN_ROOM: 540,
  kMRC_PAYRESPCODE_ANCHOR_TURNAMENT_GIFT: 541,
  kMRC_PAYRESPCODE_TREAMPK_NO_RECIPIENT: 542,
  kMRC_PAYRESPCODE_CROSS_ROOM_BACKPACK: 543,
  kMRC_PAYRESPCODE_GIFT_DISTRIBUTION: 544,
  kMRC_USER_NOTROOMER: 600,
  kMRC_USER_BOXTASK_STATE: 601,
  kMRC_USER_BOXTASK_BOXNOTEXIST: 602,
  kMRC_USER_BOXTASK_TIME: 603,
  kMRC_USER_BOXTASK_STAT: 604,
  kMRC_USER_BOXTASK_GENORDERFAIL: 605,
  kMRC_USER_BOXTASK_JSERVER_TIMEOUT: 606,
  kMRC_USER_BOXTASK_GETCOINFAIL: 607,
  kMRC_USER_BOXTASK_GETCOINF_JSERVERFAIL: 608,
  kMRC_USER_BOXTASK_EXCEED_MAXNUM: 609,
  kMRC_USER_ACT_OBTAINFAIL: 610,
  kMRC_USER_NOT_ANCHOR: 611,
  kMRC_USER_ERR_STATE: 612,
  kMRC_USER_HAS_OBTAIN: 613,
  kMRC_USER_NO_IN_ACT: 614,
  kMRC_USER_OBTAIN_TOO_OFTEN: 615,
  kMRC_USER_USERIVALID: 616,
  kMRC_USER_ALLREADY_MANAGER: 617,
  kMRC_USER_NOT_MANAGER: 618,
  kMRC_USER_MANAGER_NUM_LIMIT: 619,
  kMRC_USER_ALREADY_HAVE_DECORATION: 620,
  kMRC_USER_NOT_HAVE_DECORATION: 621,
  kMRC_DECORATION_NOT_EXIST: 622,
  kMRC_USER_CANCEL: 623,
  kMRC_USER_ROYAL_SYIP_CANNOT_BAN: 624,
  kMRC_USER_ALREADY_CHAT_BAN: 625,
  kMRC_USER_BOXTASK_HAVE_RECEIVE_DOUBLE: 626,
  kMRC_USER_NOT_IN_WHITE_LIST: 627,
  kMRC_BADGE_USING_LIMIT: 628,
  kMRC_USER_NOT_NEW_USER: 629,
  kMRC_USER_BONUS_OVER_LIMIT_NUM: 630,
  kMRC_USER_GUARDIAN_OVER_MAX_LIMIT: 631,
  kMRC_USER_GUARDIAN_SELF_FAILED: 632,
  kMRC_USER_CONSUME_BLACK_LIST: 633,
  kMRC_USER_BLOCK_BIG_R_PRIVILEGE: 634,
  kMRC_NO_MATCHROUND: 700,
  kMRC_MATCHROUND_ERROR: 701,
  kMRC_MSG_RESENT: 800,
  kMRC_MSG_NOT_RECEIVE: 801,
  kMRC_MSG_BLACKWORD: 802,
  kMRC_MSG_STG_NUM_LIMIT: 803,
  kMRC_MSG_TOTAL_NUM_LIMIT: 804,
  kMRC_MSG_ON_BLACKLIST: 805,
  kMRC_MSG_SNDR_SIGNON_TIME_LIMIT: 806,
  kMRC_MSG_NOT_BOTH_FOLLOW: 807,
  kMRC_MSG_GROUP_NOT_OWNER: 808,
  kMRC_MSG_GROUP_KICKEDOUT_LIMIT: 809,
  kMRC_MSG_GROUP_MEMBER_LIMIT: 810,
  kMRC_MSG_GROUP_NOT_MEMBER: 811,
  kMRC_MSG_GROUP_ALREADY_JOIN: 812,
  kMRC_MSG_CREATE_GROUP_NUM_LIMIT: 813,
  kMRC_MSG_INVITE_JOIN_GROUP_LIMIT: 814,
  kMRC_MSG_GROUP_JOIN_AUTH: 815,
  kMRC_MSG_GROUP_AUTN_NOT_JOIN: 816,
  kMRC_MSG_GROUP_AUTN_NOT_LEAVE: 817,
  kMRC_MSG_GROUP_AUTN_NOT_KICK: 818,
  kMRC_MSG_GROUP_AUTN_NOT_SHARE: 819,
  kMRC_MSG_GROUP_AUTN_NOT_ADD: 820,
  kMRC_MSG_STG_FIRST: 821,
  kMRC_MSG_PIC_AUDIT_FAILED: 822,
  kMRC_MSG_TEXT_OVER_MAX_LENGTH: 823,
  kMRC_MSG_BLOCK_FAIL_BIG_R_PRIVILEGE: 824,
  kMRC_BSL_EVENT_ALREADY_EXIST: 900,
  kMRC_BSL_EVENT_NOT_EXIST: 901,
  kMRC_BSL_EVENT_OVERTIME: 902,
  kMRC_BSL_CREATE_ID_FAIL: 903,
  kMRC_BSL_AHCHOR_HAS_NOT_AUTH: 904,
  kMRC_BSL_ROOM_IN_EVENT: 905,
  kMRC_BSL_JASON_PARSE_FAIL: 906,
  kMRC_BSL_JASON_ENCODE_FAIL: 907,
  kMRC_BSL_EVENT_STATUS_ERROR: 908,
  kMRC_BSL_EVENT_NOT_FINISH: 909,
  kMRC_BSL_EVENT_TYPE_ERROR: 910,
  kMRC_BSL_FROZEN_FUND_ERROR: 911,
  kMRC_BSL_TRANSFER_FUND_ERROR: 912,
  kMRC_BSL_ROOM_NOT_VALID: 913,
  kMRC_BSL_NOT_ANCHOR_FANS: 914,
  kMRC_BSL_ANCHOR_SELF_LOT: 915,
  kMRC_BSL_NOT_FANS_GROUP: 916,
  kMRC_BSL_LIVE_NOT_ACTIVE: 917,
  kMRC_BSL_NOT_SUBS_AUTH: 918,
  kMRC_ACT_NO_OPEN: 1000,
  kMRC_ACT_NO_LOGIN: 1001,
  kMRC_ACT_NO_PRIZE_TIMES: 1002,
  kMRC_ACT_NO_PRIZES: 1003,
  kMRC_ACT_NO_EXIST: 1004,
  kMRC_ACT_LIMIT: 1005,
  kMRC_ACT_BLACK: 1006,
  kMRC_ACT_NO_ACCESS_COUNTRY: 1007,
  kMRC_ACT_END: 1008,
  kMRC_GOLD_LACK: 1009,
  kMRC_DIAMOND_LACK: 1010,
  kMRC_JSERVER_BALANCE_FAIL: 1011,
  kMRC_LOTTERY_PLATFORM_LIMIT: 1012,
  kMRC_ACT_DAY_TIMES_LIMIT: 1013,
  kMRC_ERROR_OPEN_LOTTERY_FROZEN_FUND: 1014,
  kMRC_ERROR_OPEN_LOTTERY_THAW_FUND: 1015,
  kMRC_ERROR_CAL_PRIZE: 1016,
  kMRC_ERROR_GENERATE_UNIQUE: 1017,
  kMRC_ERROR_CAN_NOT_REMOVE: 1018,
  kMRC_ERROR_REPEAT_REMOVE: 1019,
  kMRC_ERROR_UNMATCH_REMOVE_PRIZEID: 1020,
  kMRC_TASK_UNFINISH: 1021,
  kMRC_REPEAT_RECV_PRIZE: 1022,
  kMRC_NO_TASK: 1023,
  kMRC_NO_ACCESS_ROOMTYPE: 1024,
  kMRC_ROOM_GRADE_NOT_OPEN: 1025,
  kMRC_ROOM_GRADE_CANNOT_CHG: 1026,
  kMRC_ROOM_GRADE_PAY_REPEAT: 1027,
  kMRC_ROOM_GRADE_PAY_SYS_FAILED: 1028,
  kMRC_ROOM_GRADE_LACK_MONEY: 1029,
  kMRC_ROOM_GRADE_PAY_ERROR: 1030,
  kMRC_CREATE_VOICEROOM_FAILED: 1031,
  kMRC_VOICEROOM_MEMBER_INFO_FAILED: 1032,
  kMRC_VOICEROOM_IN_ROOM: 1033,
  kMRC_VOICEROOM_NOT_IN_ROOM: 1034,
  kMRC_VOICEROOM_REPEAT_JOIN: 1035,
  kMRC_VOICEROOM_MEM_COUNT_FAILED: 1036,
  kMRC_VOICEROOM_JOIN_LIMIT: 1037,
  kMRC_VOICEROOM_FULL: 1038,
  kMRC_VOICEROOM_JOIN_LACK_MONEY: 1039,
  kMRC_VOICEROOM_JOIN_PAY_ERROR: 1040,
  kMRC_VOICEROOM_IS_MANAGER: 1041,
  kMRC_VOICEROOM_IS_ROOM_OWNER: 1042,
  kMRC_VOICEROOM_NOT_MANAGER: 1043,
  kMRC_VOICEROOM_MANAGER_LIMIT: 1044,
  kMRC_VOICEROOM_NOT_ROOM_OWNER: 1045,
  kMRC_VOICEROOM_DAY_REWARD_LIMIT: 1046,
  kMRC_VOICEROOM_SET_MANAGER_NO_PERMISSION: 1047,
  kMRC_VOICEROOM_SET_MANAGER_JOIN_LIMIT: 1048,
  kMRC_VOICEROOM_SET_MANAGER_ROOMFULL: 1049,
  kMRC_VOICEROOM_FIRE_MANAGER_NO_PERMISSION: 1050,
  kMRC_VOICEROOM_BE_FIRED_NOT_MANAGER: 1051,
  kMRC_VOICEROOM_CONVENE_LIMIT: 1060,
  kMRC_ACT_RECV_PRIZE_FAILED: 1061,
  kMRC_ACT_RPRIZE_ALREADY_RECV: 1062,
  kMRC_SVIP_POINTS_EXG_SUBMIT: 1063,
  kMRC_SVIP_POINTS_EXG_PASS: 1064,
  kMRC_SVIP_POINTS_EXG_LACK_OF_POINTS: 1065,
  kMRC_SVIP_POINTS_EXG_LACK_OF_TIME: 1066,
  kMRC_GOLD_PIECE_LACK: 1067,
  kMRC_ALREADY_SYNTHESIZE_GOLD: 1068,
  kMRC_GOLD_PIECE_NOT_ENOUGH: 1069,
  kMRC_GOLD_PIECE_LIMIT: 1070,
  kMRC_GOLD_PIECE_EXIST_ORDER: 1071,
  kMRC_GOLD_PIECE_CHECK_LOTTERY_LIMIT: 1072,
  kMRC_VOTE_PROHIBIT: 1100,
  kMRC_VOTE_ACTIVING: 1102,
  kMRC_VOTE_NOACTIVING: 1104,
  kMRC_VOTE_AUDIT_ERROR: 1105,
  kMRC_SEN_REPEAT_ADD: 1200,
  kMRC_UNEXPECTED_ANCHOR_LEVEL: 1300,
  kMRC_ANCHOR_NOT_FOUND: 1301,
  kMRC_REDIS_KEY_EXISTS: 1302,
  kMRC_ANCHOR_TASK_ALREADY_COMPLETE: 1303,
  kMRC_ANCHOR_UNEXPECTED_TASK_TYPE: 1304,
  kMRC_ANCHOR_PARSE_TASK_PARAMS_FAILED: 1305,
  kMRC_ANCHOR_TASK_ADD_EXP: 1306,
  kMRC_ANCHOR_TASK_DETAIL_MODIFED: 1307,
  kMRC_ANCHOR_UNEXPECTED_PARAM: 1308,
  kMRC_ANCHOR_PK_INVALID_DURATION: 1321,
  kMRC_ANCHOR_PK_FROM_ANCHOR_WAIT_ANSWER: 1322,
  kMRC_ANCHOR_PK_FROM_ANCHOR_TO_ANSWER: 1323,
  kMRC_ANCHOR_PK_FROM_ANCHOR_PKING: 1324,
  kMRC_ANCHOR_PK_FROM_ANCHOR_SHOW_RESULT: 1325,
  kMRC_ANCHOR_PK_FROM_ANCHOR_FLEE_PUNISH: 1326,
  kMRC_ANCHOR_PK_TO_ANCHOR_WAIT_ANSWER: 1327,
  kMRC_ANCHOR_PK_TO_ANCHOR_TO_ANSWER: 1328,
  kMRC_ANCHOR_PK_TO_ANCHOR_PKING: 1329,
  kMRC_ANCHOR_PK_TO_ANCHOR_SHOW_RESULT: 1330,
  kMRC_ANCHOR_PK_TO_ANCHOR_FLEE_PUNISH: 1331,
  kMRC_ANCHOR_PK_ASK_PK_DAILY_MAX_COUNT: 1332,
  kMRC_ANCHOR_PK_ANSWER_PK_TIMEOUT: 1333,
  kMRC_ANCHOR_PK_FROM_ANCHOR_STATE_ERROR: 1334,
  kMRC_ANCHOR_PK_TO_ANCHOR_STATE_ERROR: 1335,
  kMRC_ANCHOR_PK_NOT_IN_LIVE_INTER: 1336,
  kMRC_ANCHOR_PK_PK_NOT_FOUND: 1337,
  kMRC_ANCHOR_PK_PUNISH_CONTENT_SENSITIVE: 1338,
  kMRC_ANCHOR_PK_NOT_HAVE_ACTIVITY: 1339,
  kMRC_ANCHOR_PK_LOW_VERSION: 1340,
  kMRC_ANCHOR_PK_ZX_PUNISHING_NOW: 1341,
  kMRC_ANCHOR_PK_OPPOSITE_ZX_PUNISHING_NOW: 1342,
  kMRC_BOX_LOTTERY_UNEXPECTED_ROUND: 1400,
  kMRC_BOX_LOTTERY_ROUND_NOT_CLOSE: 1401,
  kMRC_BOX_LOTTERY_UNEXPECTED_BET_GEMS: 1402,
  kMRC_BOX_LOTTERY_UNEXPECTED_BOX_ID: 1403,
  kMRC_BOX_LOTTERY_CONSUME_BLACK_USER: 1404,
  kMRC_BOX_LOTTERY_CONSUME_BAD_PARAM: 1405,
  kMRC_BOX_LOTTERY_REPEAT_CONSUME: 1406,
  kMRC_BOX_LOTTERY_CONSUME_REQUEST_TOO_OFTEN: 1407,
  kMRC_BOX_LOTTERY_CONSUME_BAD_BUSINESSID: 1408,
  kMRC_BOX_LOTTERY_INSUFFICIENT_BALANCE: 1409,
  kMRC_BOX_LOTTERY_LACK_OF_BALANCE: 1410,
  kMRC_BOX_LOTTERY_CONSUME_ERROR: 1411,
  kMRC_BOX_LOTTERY_CONSUME_INTERNAL_ERROR: 1412,
  kMRC_BOX_LOTTERY_CONSUME_IP_ERROR: 1413,
  kMRC_BOX_LOTTERY_CONSUME_UDB_TOKEN_ERROR: 1414,
  kMRC_BOX_LOTTERY_CHECK_CURRENCY_FAIL: 1415,
  kMRC_BOX_LOTTERY_MQ_CONSUME_ERROR: 1416,
  kMRC_BOX_LOTTERY_CONSUME_AMOUNT_ERROR: 1417,
  kMRC_BOX_LOTTERY_CONSUME_GIF_ERROR: 1418,
  kMRC_BOX_LOTTERY_CONSUME_GIF_PRICE_ERROR: 1419,
  kMRC_BOX_LOTTERY_CONSUME_GIF_NOT_CONSISTENCY: 1420,
  kMRC_BOX_LOTTERY_CONSUME_NO_BETTING: 1421,
  kMRC_BOX_LOTTERY_CONSUME_SIGN_ERROR: 1422,
  kMRC_BOX_LOTTERY_NOT_IN_WHITE_LIST: 1423,
  kMRC_BOX_LOTTERY_BOX_EXCESS_LIMIT: 1424,
  kMRC_BOX_LOTTERY_NOT_BET_STAGE: 1425,
  kMRC_GAME_ENCOURAGE_AWARD_NOT_START: 1426,
  kMRC_GAME_ENCOURAGE_AWARD_CONDITION_FAILED: 1427,
  kMRC_BOX_LOTTERY_GIFT_TASK_UNFINISHED: 1428,
  kMRC_BOX_LOTTERY_GIFT_TASK_ACT_END: 1429,
  kMRC_BOX_LOTTERY_GIFT_TASK_NO_ACNHOR: 1430,
  kMRC_GAME_RECOMMEND_EXPIRED: 1431,
  kMRC_AV_POST_CDN_REQ_FAILED: 1500,
  kMRC_AV_POST_UNEXPECTED_PATH: 1501,
  kMRC_AV_POST_UNEXPECTED_TX_VOD_EVENT: 1502,
  kMRC_JSON_PARSE_FAILED: 1503,
  kMRC_AV_POST_UNEXPECTED_CDN: 1504,
  kMRC_AV_POST_SIGN_NOT_MATCH: 1505,
  kMRC_AV_POST_TASK_CALLBACK_HANDLED: 1506,
  kMRC_AV_POST_TASK_RUN_FAILED: 1507,
  kMRC_AV_POST_RSC_NOT_FOUND_IN_CALLBACK: 1508,
  kMRC_AV_POST_CDN_CONFIG_NOT_FOUND: 1509,
  kMRC_AV_POST_TASK_ID_NOT_FOUND: 1510,
  kMRC_AV_POST_NOT_SUPPORT_YET: 1511,
  kMRC_AV_POST_NEED_COLLECT: 1512,
  kMRC_AV_POST_WAIT_COLLECT: 1513,
  kMRC_AV_POST_REAL_CLIP_PUNISH: 1514,
  kMRC_AV_MEDIAUI_FAILED: 1515,
  kMRC_BG_NO_BOX: 1600,
  kMRC_BG_USER_AWARDED: 1601,
  kMRC_BG_AWARD_FAILED: 1602,
  kMRC_BG_NO_AWARD: 1603,
  kMRC_BG_NOT_AWARDING: 1604,
  kMRC_CHECKIN_ALREADY_CHECKIN: 1700,
  kMRC_CHECKIN_ACTIVITYID_ERR: 1701,
  kMRC_CHECKIN_ACTIVITY_NOT_READY: 1702,
  kMRC_CHECKIN_ACTIVITY_NO_COUNTRY: 1703,
  kMRC_CHECKIN_DEVICE_DUPLICATE: 1704,
  kMRC_CHECKIN_HIDE: 1705,
  kMRC_CHECKIN_NOT_FOLLOW: 1706,
  kMRC_CHECKIN_TASK_UNIFINISH: 1707,
  kMRC_CHECKIN_UNAUTH: 1708,
  kMRC_CHECKIN_ACTIVITY_NO_PLATFORM: 1709,
  kMRC_LUCKYGIFT_NOT_BEGIN: 1800,
  kMRC_TASKBOX_ACTIVITY_NOT_FOUND: 1900,
  kMRC_TASKBOX_ACTIVITY_NO_COUNTRY: 1901,
  kMRC_TASKBOX_USER_GAME_OVER: 1902,
  kMRC_TASKBOX_NO_REWARD_LEFT: 1903,
  kMRC_PICKME_ANCHOR_NOT_AUTH: 2000,
  kMRC_PICKME_NOT_FOUND: 2001,
  kMRC_PICKME_STATE_ERROR: 2002,
  kMRC_PICKME_TYPE_ERROR: 2003,
  kMRC_PICKME_SELF_PART: 2004,
  kMRC_PICKME_NOT_QUALIFIED: 2005,
  kMRC_PICKME_AUDIT_ERROR: 2006,
  kMRC_PICKME_INTASK_MAXCOUNTS: 2007,
  kMRC_PICKME_FROZEN_FUND_ERROR: 2008,
  kMRC_PICKME_NOT_SHARED: 2009,
  kMRC_PICKME_NOT_FANS_GROUP: 2010,
  kMRC_PICKME_NOT_SUBS: 2011,
  kMRC_PICKME_AWARD_MAXNUM: 2012,
  kMRC_PICKME_ONGOING_EXIST: 2013,
  kMRC_PICKME_FANS_LEVEL_LIMIT: 2014,
  kMRC_PICKME_ANCHOR_NOT_GUILD: 2015,
  kMRC_BIND_DUPLICATE: 2100,
  kMRC_LIVE_IS_ON: 2101,
  kMRC_START_REPUSH: 2102,
  kMRC_REPUSH_DOING: 2103,
  kMRC_MEETING_NOT_SUPPORT_VERSION: 2200,
  kMRC_MEETING_INVAILD_SESSIONID: 2201,
  kMRC_MEETING_UP_ALREADY: 2202,
  kMRC_MEETING_WAIT_ALREADY: 2203,
  kMRC_MEETING_INVITE_INVAILD: 2204,
  kMRC_MEETING_INVITE_TIMEOUT: 2205,
  kMRC_MEETING_CLOSED: 2206,
  kMRC_MEETING_NOT_UP: 2207,
  kMRC_MEETING_NOT_WAIT: 2208,
  kMRC_MEETING_ANCHOR_MUTE_ALL: 2209,
  kMRC_MEETING_ANCHOR_LOCK_ALL: 2210,
  kMRC_MEETING_WAIT_REACH_LIMIT: 2211,
  kMRC_MEETING_USER_MUTE_SELF_OR_EXIT: 2212,
  kMRC_MEETING_BANNED: 2213,
  kMRC_MEETING_BANNED_BY_AUTH: 2214,
  kMRC_LINKMIC_NOT_SUPPORT_VERSION: 2300,
  kMRC_LINKMIC_NOT_SUPPORT_GAMEID: 2301,
  kMRC_LINKMIC_NOT_SPPPORT_AREA: 2302,
  kMRC_LINKMIC_AIR_AUTH_FAILED: 2303,
  kMRC_LINKMIC_LIVE_OFFLINE: 2304,
  kMRC_LINKMIC_REFUSE_INVITE_BY_CONF: 2305,
  kMRC_LINKMIC_PROCESSING: 2306,
  kMRC_LINKMIC_RANDOM_NOT_OPEN: 2307,
  kMRC_LINKMIC_RANDOM_WAITING: 2308,
  kMRC_LINKMIC_RANDOM_NOT_WAITING: 2309,
  kMRC_LINKMIC_RANDOM_WAIT_RESPONSE: 2310,
  kMRC_LINKMIC_RANDOM_INVAILD_MATCH: 2311,
  kMRC_LINKMIC_RANK_PK_NOT_OPEN: 2312,
  kMRC_LINKMIC_RANK_PK_WAITING: 2313,
  kMRC_LINKMIC_RANK_PK_NOT_WAITING: 2314,
  kMRC_LINKMIC_RANK_PK_WAIT_RESPONSE: 2315,
  kMRC_LINKMIC_RANK_PK_INVAILD_MATCH: 2316,
  kMRC_LINKMIC_RANK_PK_NOT_AVAILABLE_PK: 2317,
  kMRC_LINKMIC_ZX_PUNISHING_NOW: 2318,
  kMRC_LINKMIC_OPPOSITE_ZX_PUNISHING_NOW: 2319,
  kMRC_LINKMIC_ACTIVITY_INCOMING: 2321,
  kMRC_LINKMIC_NOT_SUPPORT_DEVICE: 2320,
  kMRC_LINKMIC_AUDIENCE_WAIT_FULL: 2350,
  kMRC_LINKMIC_AUDIENCE_NOT_IN_ROOM: 2351,
  kMRC_LINKMIC_AUDIENCE_NOT_AVAILABLE_SEAT: 2352,
  kMRC_LINKMIC_TEAM_NOT_IN_TEAM: 2360,
  kMRC_LINKMIC_TEAM_SELF_ALREADY_IN_TEAM: 2361,
  kMRC_LINKMIC_TEAM_OTHER_ALREADY_IN_TEAM: 2362,
  kMRC_LINKMIC_TEAM_INVITING: 2363,
  kMRC_LINKMIC_TEAM_INVITE_INVAILD: 2364,
  kMRC_LINKMIC_TEAM_NEED_LEADER_AUTH: 2365,
  kMRC_LINKMIC_TEAM_FULL: 2366,
  kMRC_LINKMIC_TEAM_STARTED: 2367,
  kMRC_LINKMIC_TEAM_JOIN_ALREADY: 2368,
  kMRC_LINKMIC_TEAM_FULL_CAN_START: 2369,
  kMRC_LINKMIC_TEAM_HAS_WAIT: 2370,
  kMRC_LINKMIC_TEAM_SIZE_NOT_MATCH: 2371,
  kMRC_LINKMIC_TEAM_SIZE_EMPTY: 2372,
  kMRC_LINKMIC_TEAM_UNSUPPORT_OP: 2373,
  kMRC_LINKMIC_TEAM_CHANGE_SEAT_INVALID: 2374,
  kMRC_AIR_RAID_PROCESSING: 2400,
  kMRC_WEALTH_FAMILY_CREATE_LIMIT: 2500,
  kMRC_WEALTH_FAMILY_NAME_ILLEAGE: 2501,
  kMRC_WEALTH_FAMILY_SLOGAN_ILLEAGE: 2502,
  kMRC_WEALTH_FAMILY_NAMEREPEAT: 2503,
  kMRC_WEALTH_FAMILY_CREATE_NOAUTH: 2504,
  kMRC_WEALTH_FAMILY_IN_OTHER_FAMILY: 2505,
  kMRC_WEALTH_FAMILY_APPLY_WAIT: 2506,
  kMRC_WEALTH_FAMILY_NOT_OWNER: 2507,
  kMRC_WEALTH_FAMILY_MEMBER_LIMIT: 2508,
  kMRC_WEALTH_FAMILY_REMOVE_OWNER: 2509,
  kMRC_WEALTH_FAMILY_NOT_MEMBER: 2510,
  kMRC_WEALTH_FAMILY_APPLY_EXPIRE: 2511,
  kMRC_WEALTH_FAMILY_REVOKE_PUNISH: 2512,
  kMRC_WEALTH_FAMILY_USER_REVOKE: 2513,
  kMRC_WEALTH_FAMILY_NOT_AUTHORITY: 2514,
  kMRC_WEALTH_FAMILY_NOT_ENOUGH: 2515,
  kMRC_WEALTH_FAMILY_WEALTH_NOT_ENOUGH: 2516,
  kMRC_WEALTH_FAMILY_CLUMP_PROCESSING: 2517,
  kMRC_WEALTH_FAMILY_CLUMP_NUM_LIMIT: 2518,
  kMRC_WEALTH_FAMILY_FOLLOW_USER_LIMIT: 2519,
  kMRC_WEALTH_FAMILY_FOLLOW_FAMILY_LIMIT: 2520,
  kMRC_WEALTH_FAMILY_FOLLOW_MEMBER_UNFOLLOW: 2521,
  kMRC_WEALTH_FAMILY_AVATAR_IN_REVIEW: 2522,
  kMRC_WEALTH_FAMILY_AVATAR_AUDIT_LIMIT: 2523,
  kMRC_THIRD_PARTY_END: 2600,
  kMRC_PROMOTRE_OVERDUE: 2601,
  kMRC_START_ACTIVITY_FAIL: 2602,
  kMRC_REPEAT_REQUEST: 2603,
  kMRC_IG_NOT_FOUND: 2701,
  kMRC_IG_STATUS_ERROR: 2702,
  kMRC_IG_TYPE_ERROR: 2703,
  kMRC_IG_SELF_PART: 2704,
  kMRC_IG_AUDIT_ERROR: 2706,
  kMRC_IG_FROZEN_FUND_ERROR: 2708,
  kMRC_IG_ACTIVITY_ALREADY_EXECUTED: 2709,
  kMRC_SLOTMACHINE_NOT_FREE_MODE: 2800,
  kMRC_SLOTMACHINE_NOT_IN_WHITE_LIST: 2801,
  kMRC_SLOTMACHINE_IN_BLACK_LIST: 2802,
  kMRC_SLOTMACHINE_INSUFFICIENT_BALANCE_EXCHANGE: 2803,
  kMRC_ROULETTE_NOT_BET_STAGE: 2804,
  kMRC_GAMEPROMOTE_ACCEPTED: 2805,
  kMRC_SLOTMACHINE_JACKPOT_INBALANCE: 2806,
  kMRC_PRIZE_POOL_RUN_OUT: 2807,
  kMRC_BAUCUA_NOT_BET_STATUS: 2808,
  kMRC_SLOTMACHINE_UNUSED_FREE_TIMES: 2809,
  kMRC_AD_WATCHTOGETHER_NOT_AUTH: 2901,
  kMRC_TURNTABLE_START_ERROR: 3000,
  kMRC_TURNTABLE_NOT_ENOUGH_MONEY: 3001,
  kMRC_TURNTABLE_STATUS_ERROR: 3002,
  kMRC_TURNTABLE_NOT_ACTIVITY: 3003,
  kMRC_TURNTABLE_ENOUGH_PLAYER: 3004,
  kMRC_TURNTABLE_ALREADY_PARTIC: 3005,
  kMRC_TURNTABLE_ROOM_NOT_ONLINE: 3006,
  kMRC_TURNTABLE_DEDUCTION_FUND_FAIL: 3007,
  kMRC_TURNTABLE_REQ_FUNC_FAIL: 3008,
  kMRC_TURNTABLE_START_ERR_AUTH: 3009,
  kMRC_TURNTABLE_PARTICIPATE_ERR_AUTH: 3010,
  kMRC_TURNTABLE_GOING_ERR_AUTH: 3011,
  kMRC_TURNTABLE_HS_START_ERR_AUTH: 3030,
  kMRC_TURNTABLE_HS_PARTICIPATE_ERR_AUTH: 3031,
  kMRC_TURNTABLE_HS_GO_ERR_AUTH: 3032,
  kMRC_TURNTABLE_HS_EXIT_ERR_AUTH: 3033,
  kMRC_TURNTABLE_HS_START_ERROR: 3034,
  kMRC_ITEM_TALLY_TEXT_AUTH_ERR: 3100,
  kMRC_ANCHOR_GROWTH_TASK_NOT_START: 3200,
  kMRC_ANCHOR_GROWTH_TASK_EXPIRED: 3201,
  kMRC_VOICE_ROOM_PAIRING_NOT_IN_WHITELIST: 3300,
  kMRC_VOICE_ROOM_PAIRING_NO_PERMISSION: 3301,
  kMRC_VOICE_ROOM_PAIRING_STATUS_ERR: 3302,
  kMRC_VOICE_ROOM_PAIRING_NOT_ENOUGH_CONSUMPTION: 3303,
  KMRC_FAMILY_CLUMP_START_ERR_NOT_AUTH: 3400,
  KMRC_FAMILY_CLUMP_START_ERR_LIMIT: 3401,
  KMRC_FAMILY_CLUMP_START_ERR_DOING: 3402,
  KMRC_FAMILY_CLUMP_PARTIC_ERR_NOT_FOLLOW: 3403,
  KMRC_FAMILY_CLUMP_PARTIC_ERR_ALREADY: 3404,
  KMRC_FAMILY_CLUMP_START_ERR_NOT_ENOUGH: 3405,
  KMRC_FAMILY_CLUMP_START_ERR_ROOM_LIMIT: 3406,
  KMRC_FAMILY_CLUMP_PARTIC_ERR_NOT_ACTIVITY: 3407,
  KMRC_FAMILY_CLUMP_BLACK_LIST: 3408,
  KMRC_DRAW_PARTIC_ERR_NOT_AUTH: 3520,
  KMRC_DRAW_ERR_TIMES_LIMIT: 3522,
  KMRC_DRAW_PARTIC_ERR_FAIL: 3521,
  KMRC_DRAW_PARTIC_ERR_ACTIVITY_END: 3523,
  KMRC_DRAW_PARTIC_ERR_NOT_ACTIVITY: 3524,
  KMRC_DRAW_PARTIC_ERR_STATUS_ERR: 3525,
  KMRC_DRAW_ERR_NOT_ACTIVITY: 3526,
  KMRC_DRAW_ERR_STATUS_ERR: 3527,
  KMRC_DRAW_ERR_REPEAT_REQUEST: 3528,
  KMRC_DRAW_GET_ERR_SOLD_OUT: 3530,
  KMRC_DRAW_GET_ERR_DO_REWARD: 3531,
  KMRC_FALL_GIFT_RECEIVE_ERR_ROOM_LIMIT: 3600,
  KMRC_FALL_GIFT_RECEIVE_ERR_TOTAL_LIMIT: 3601,
  KMRC_VINDICATE_WALL_NO_BALANCE: 3700,
  KMRC_VINDICATE_WALL_DEDUCTION_FAIL: 3701,
  KMRC_VINDICATE_WALL_TEXT_AUDIT: 3702,
  KMRC_VINDICATE_WALL_ACT_TIME_LIMIT: 3703,
  kMRC_REPLAY_REALTIME_NOT_SUPPORT: 3800,
  kMRC_BOSS_GAME_ERR_NO_PERMISSION: 3900,
  kMRC_BOSS_GAME_ERR_TIMES_LIMIT: 3901,
  kMRC_BOSS_GAME_ERR_IN_BLACK_LIST: 3902,
  kMRC_BOSS_GAME_ERR_IS_ONGOING: 3903,
  KMRC_BOSS_GAME_ERR_NOT_OPEN: 3904,
  kMRC_FISING_PRIZE_POOL_ERROR: 4000,
  kMRC_FISING_GAME_NOT_EXIST: 4001,
  kMRC_FISING_LACK_OF_BALANCE: 4002,
  kMRC_FISING_GAME_NUM_LIMIT: 4003,
  kMRC_FISING_CATCH_REQ_REPEAT: 4004,
  kMRC_ADVENTURE_ERROR: 4100,
  kMRC_ADVENTURE_INACTIVE: 4101,
  kMRC_ADVENTURE_NOT_MASTER: 4102,
  kMRC_NO_TREASURE: 4200,
  kMRC_BET_TOO_MUCH: 4201,
  kMRC_ROUND_NOT_EXIST: 4202,
  kMRC_ROUND_NOT_BET: 4203,
  kMRC_GIFTLUCKYBAG_CLOSE: 4210,
  kMRC_GIFTLUCKYBAG_PARTICIPATED: 4211,
  kMRC_GIFTLUCKYBAG_END: 4212
};
Nimo.NimoRspCode = NimoRspCode;
export default NimoRspCode;