import _extends from "@babel/runtime/helpers/extends";
/**
 * @typedef {{
 * onSuccess?: () => void;
 * commercialGameId?: string;
 * from?: string;
 * redirectUseBack?: boolean;
 * hideThirdParty?: boolean;
 * showBackBtn?: boolean
 * }} Options
 */

/**
 * @type {Options}
 */
export var loginOptions = {};

/**
 * @param {Partial<Options>} partialOptions
 */
export function setLoginOptions(partialOptions) {
  _extends(loginOptions, partialOptions);
}