import { toUpperFirstLetter, transformUpperKebabCaseToCamelCase } from '@client/common/utils/string';
export var VideoEvents = ['error', 'loadstart', 'loadedmetadata', 'loadeddata', 'canplay', 'canplaythrough', 'waiting', 'play', 'playing', 'pause', 'volumechange', 'ratechange', 'progress', 'timeupdate', 'durationchange', 'ended', 'seeking', 'seeked', 'complete', 'emptied', 'stalled', 'suspend'];
export function genEventsName(event) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$prefix = _ref.prefix,
    prefix = _ref$prefix === void 0 ? '' : _ref$prefix,
    _ref$suffix = _ref.suffix,
    suffix = _ref$suffix === void 0 ? '' : _ref$suffix;
  var listenerName = transformUpperKebabCaseToCamelCase(event);
  listenerName = "on".concat(prefix).concat(toUpperFirstLetter(listenerName)).concat(suffix);
  return listenerName;
}
export default {
  /*
   * event: MEDIA_ELEMENT_ATTACH
   * desc: 媒体元素与播放器关联
   * data: {
   *          value: <MediaElement> - 关联的video或audio元素
   *       }
   */
  MEDIA_ELEMENT_ATTACH: 'media.element.attach',
  /*
   * event: MEDIA_ELEMENT_DETACH
   * desc: 媒体元素与播放器取消关联
   * data: {
   *          value: <MediaElement> - 取消关联的video或audio元素
   *       }
   */
  MEDIA_ELEMENT_DETACH: 'media.element.detach',
  /*
   * event: PLAYER_STATE_CHANGE
   * desc: 播放器状态变动
   * data:  {
   *          key: <string> - 发生变动的状态key
   *          value: <any>  - 新状态值
   *        }
   */
  PLAYER_STATE_CHANGE: 'player.state.change',
  /*
   * event: PLAYER_READY
   * desc: 播放器准备就绪
   * data: undefined
   */
  PLAYER_READY: 'player.ready',
  /*
   * event: PLAYER_DISPOSE
   * desc: 播放器卸载完成
   * data: undefined
   */
  PLAYER_DISPOSE: 'player.dispose',
  /*
   * evnet: PLAYER_PLUGIN_READY
   * desc:插件初始化完成
   * data:  {
   *          name: <string>  - 完成初始化的插件名
   *          value: <object> - 完成初始化的插件实例
   *        }
   */
  PLAYER_PLUGIN_READY: 'player.plugin.ready',
  /*
   * evnet: PLAYER_PLUGIN_DISPOSE
   * desc:插件注销完成
   * data:  {
   *          name: <string>  - 完成注销的插件名
   *          value: <object> - 完成注销的插件实例
   *        }
   */
  PLAYER_PLUGIN_DISPOSE: 'player.plugin.dispose',
  /*
   * event: STREAM_INFO_UPDATE
   * desc: 播放媒体信息更新
   * data: {
   *          value: <MediaInfo> - 更新后的媒体信息
   *       }
   */
  STREAM_INFO_UPDATE: 'stream.info.update',
  /*
   * event: STREAM_INFO_LOCKED
   * desc: 播放器媒体信息锁定（不可修改）
   * data: {
   *          value: <Boolean> - 是否锁定
   * }
   */
  STREAM_INFO_LOCKED: 'stream.info.locked',
  /*
   * event: STREAM_INFO_WILL_UPDATE
   * desc: 流信息即将改变（不一定改变成功）
   * data: {
   *          value: <MediaInfo> - 改变后的媒体信息
   * }
   */
  STREAM_INFO_WILL_UPDATE: 'stream.rate.will.update',
  /*
   * event: STREAM_STAT_INFO_UPDATE
   * desc: 流统计信息变化
   * data: {
   *          value: { - 新信息
   *            appendedCodedFrames: <number> 新增片段包含的视频帧数
   *            jitterBufferLength: <number>  当前流jitterBuffer缓存时长（单位：ms），仅flv流有值，hls为0
   *          }
   * }
   */
  STREAM_STAT_INFO_UPDATE: 'stream.stat.info.update',
  /*
   * event: LIVE_BEGIN_NOTICE
   * desc: 上播通知
   * data: nimobase->LiveBeginNotice
   */
  LIVE_BEGIN_NOTICE: 'live.begin.notice',
  /*
   * event: LIVE_END_NOTICE
   * desc: 下播通知
   * data: nimobase->LiveEndNotice
   */
  LIVE_END_NOTICE: 'live.end.notice',
  /*
   * event: STREAM_PLAYBACK_NOTIFY
   * desc: 回放通知
   * data: 业务流包
   */
  STREAM_PLAYBACK_NOTIFY: 'stream.playback.notify',
  /*
   * event: CONFIG_UPDATE
   * desc: 播放器配置项更改
   * data: {
   *          value: <newConfig> - 新配置项
   *       }
   */
  CONFIG_UPDATE: 'config.update',
  /*
   * event: TECH_UPDATE
   * desc: videojs tech切换（每次tech切换都会刷新video标签，为了确保部分video事件能实时监听到，需在video标签刷新时重新监听事件）
   * data:  {
   *          value: <VideojsTech> - 新的tech实例
   *        }
   */
  TECH_UPDATE: 'tech.update',
  /*
   * event: LOAD_START
   * desc: 调用播放器load方法触发
   * data: undefined
   */
  LOAD_START: 'load.start',
  /*
   * event: LOADED_DATA
   * desc: 播放器加载出第一帧画面（loadeddata 事件）
   * data: undefined
   */
  LOADED_DATA: 'loaded.data',
  /*
   * event: VIDEO_FRAME_LOADED
   * desc: 视频播放前，加载到足够的数据事件
   * data: undefined
   */
  VIDEO_FRAME_LOADED: 'video.frame.loaded',
  /*
   * event: LINK_UNAVAILABLE
   * desc: 连接请求失败
   * data: undefined
   */
  LINK_UNAVAILABLE: 'link.unavailable',
  /*
   * event: VIDEO_ENDED
   * desc: 视频结束
   * data: undefined
   */
  VIDEO_ENDED: 'video.ended',
  /*
   * event: MEDIA_STREAM_UPDATE
   * desc: 流信息更新
   * data: {
   *    rate: 6,
   *    cdn: 2,
   *    mediaType: 1,
   *    url: 'https://xxxxx.flv',
   *    streamName: 'suxxxxxxx'
   * }
   */
  MEDIA_STREAM_UPDATE: 'media.stream.update',
  /*
   * event: VIDEO_FIRST_START
   * desc: 视频优先策略开始，原来用LOAD_START,因为中台流可能自己请求流地址，在不在播的时候不触发 LOAD_START
   * data: undefined
   */
  VIDEO_FIRST_START: 'video.first.start',
  /*
   * event: ROOM_NO_STREAM
   * desc: 房间没有流
   * data: undefined
   */
  ROOM_NO_STREAM: 'room.no.stream',
  /*
   * event: MEDIA_STREAM_OTHER_UPDATE
   * desc: fps 等媒体信息更新
   * data: undefined
   */
  MEDIA_STREAM_OTHER_UPDATE: 'media.stream.other.update',
  /*
   * event: SWITCH_DONE
   * desc: 无缝切换完成事件
   * data: undefined
   */
  SWITCH_DONE: 'switch.done',
  /*
   * event: UPDATE_INTENSITY
   * desc: 更新音量强度变化
   * data: {uid: 用户uid, intensity: 音量值} 
   */
  UPDATE_INTENSITY: 'UPDATE_INTENSITY',
  /*
   * event: NEED_UPDATE_M3U8
   * desc: 更新回放m3u8流
   * data: undefined
   */
  NEED_UPDATE_M3U8: 'NEED_UPDATE_M3U8',
  /*
   * event: LOAD_REPLAY
   * desc: 开始播放回放流
   * data: undefined
   */
  LOAD_REPLAY: 'LOAD_REPLAY',
  /*
   * event: REPLAY_TO_LIVE
   * desc: 回放返回直播，播放直播流
   * data: undefined
   */
  REPLAY_TO_LIVE: 'REPLAY_TO_LIVE',
  PLAY_FORCE: 'player.force.play'
};