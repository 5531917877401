import NimoBuss from "../nimobuss";
import Taf from "@nimo-fed/taf-lib-wup";
var FollowRequestView = function FollowRequestView() {
  this.relationship = 0;
  this.anchorId = 0;
  this.code = 0;
};
FollowRequestView.prototype._clone = function () {
  return new NimoBuss.FollowRequestView();
};
FollowRequestView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowRequestView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowRequestView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.relationship);
  os.writeInt64(1, this.anchorId);
  os.writeInt32(2, this.code);
};
FollowRequestView.prototype.readFrom = function (is) {
  this.relationship = is.readInt32(0, false, this.relationship);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.code = is.readInt32(2, false, this.code);
};
NimoBuss.FollowRequestView = FollowRequestView;
export default FollowRequestView;