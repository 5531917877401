import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var OMSFunctionID = {
  kAnchorCheckRoom: 1006,
  kAnchorVideoControlList: 1007,
  kUserBlockBigRPrivilege: 1008,
  kAnchorShow1080PLive: 1010,
  kAnchorShowLiveRecmdPVStat: 1011,
  kMerchantList: 1015,
  kWishReward: 1016,
  kAnchorPCLiveTypeChange: 1018,
  kAnchorVirtualIdol: 2001,
  kGameRankBlackList: 3010,
  kAnchorTabooMax: 10001,
  kDiamonBoxLottery: 10002,
  kAnchorNoShowLevel: 10003,
  kAnchorAllGear: 10004,
  kAnchorAward: 10006,
  kAnchorEndLiveTutorial: 10007,
  kUserLuckyGift: 10008,
  kAnchorLottery: 10009,
  kAnchorLiveInProhibitedArea: 10010,
  kAnchorMessage: 10012,
  kAnchorThirdPartyRepushWatermark: 10014,
  kUserGoldenBeansGamesCollect: 10017,
  kAnchorMultiLinkMic: 10018,
  kUserGroupChat: 10019,
  kUserGoldenBeansGamesExchange: 10020,
  kUserGoldBeanLottery: 10021,
  kUserGoldBeanBlackList: 20005,
  kUserOfficialStaff: 10023,
  kUserEnterpriseWithdrawalLimit: 10024,
  kAnchorShareRewardWhiteList: 10026,
  kAnchorShareRewardBlackList: 10027,
  kUserCurrencyDealer: 10028,
  kAnchorPush: 10029,
  kAlertBoxDIYResource: 10036,
  kLiveMeetingTakeSeatPush: 10041,
  kAnchorLiveReplay4App: 10042,
  kAnchorLiveRecord: 20001,
  kUserLuckyGift2: 30001,
  kUserForceLogin: 30003,
  kUserForceLoginWeb: 30005,
  kAnchorAirRaid: 100852,
  kWealthFamilyCreate: 1001000,
  kAnchorThirdPartyRepush: 1000702,
  kAnchorPickMe: 1000801,
  kAnchorPickMeBlack: 1000850,
  kSlotMachineWhite: 10030,
  kSlotMachineBlack: 20009,
  kUserPickMeJackpot: 10035,
  kAnchorBigAnchor: 10037,
  kSlotMachineDiamonWhite: 10039,
  kUserLevelExpNoDayLimit: 10044,
  kAnchorThirdPartyRepushDelay: 10045,
  kRouletteServer: 10049,
  kGoldBeanMall: 10050,
  kBoosGame: 10053,
  kBoosGameBlack: 20016,
  kReplay1080P: 10051,
  kFishingGame: 10054,
  kWarConflictGame: 10055,
  kAlertBoxTextToSpeech: 10056,
  kAnchorOptimumWatchHour: 10057,
  kEntrust: 10058,
  kAnchorPrivateRoom: 10059,
  kSlotMachineAwardGem: 10060,
  kHorseRaceWhite: 10061,
  kSlotMachineFCoinWhite: 10065,
  kBaucuaWhitelist: 10066,
  kPaidPotentialList: 10067,
  kLudoGameWhiteList: 10068,
  kAnchorNewUserServiceWhiteList: 10070,
  kGameActivityWhiteList: 10071,
  kSlotMachineTrial: 10072,
  kHunttingUser: 10073,
  kHunttingAnchor: 10074,
  kOfficialDiscordLiveNotification: 10075,
  kGameRecommend: 10078,
  kLoginProtection: 10079,
  kUserHiddenWealthValue: 10085,
  kFunnyGameHall: 10088,
  kAnchorPKUnify: 10090,
  kAnchorRoomMessageHighFreq: 10092,
  kAnchorWatchHourAlert: 10094,
  kNewUserCheckinGameProps: 10095,
  kAnchorLiveWatchHourAlert: 10096,
  kYellowCartWhite: 10098,
  kHorseRaceBlack: 20018,
  kAnchorRecordBlack: 20020,
  kDiamonBoxLotteryBlack: 20024,
  kSlotMachineDiamondBlack: 20026,
  kAnchorAssistBlack: 20025,
  kUserLevelExpStop: 20030,
  kAnchorGiftEffectBannerLimit: 20032,
  kAnchorBlockGift: 29999,
  kAnchorWatchByApp: 1000001,
  kAnchorAutoGeneratedVideo: 1000002,
  kAnchorOptionalGeneratedVideo: 1000003,
  kAnchorAllowClip: 1000004,
  kAnchorAppRecommend: 1000005,
  kAnchorWebRecommend: 1000006,
  kAnchorNeedWaterMark: 1000007,
  kRoomTypeWatchByApp: 1000008,
  kRoomTypeAutoGeneratedVideo: 1000009,
  kRoomTypeOptionalGeneratedVideo: 1000010,
  kRoomTypeAllowClip: 1000011,
  kRoomTypeAppRecommend: 1000012,
  kRoomTypeWebRecommend: 1000013,
  kRoomTypeNeedWaterMark: 1000014,
  kGameControlToShowForWeb: 1000015,
  kGameControlForServer: 1000016
};
Nimo.OMSFunctionID = OMSFunctionID;
export default OMSFunctionID;