import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import getUrlParams from '@client/common/utils/getUrlParams';

/**
 * 截取视频当前画面截图
 * @param {HTMLVideoElement} video video 元素
 */
export function screenshot(video) {
  var videoHeight = video.videoHeight,
    videoWidth = video.videoWidth;
  var canvas = document.createElement('canvas');
  canvas.width = videoWidth;
  canvas.height = videoHeight;
  canvas.getContext('2d').drawImage(video, 0, 0, videoWidth, videoHeight);
  return canvas.toDataURL() || '';
}

/**
 * 从 URL 获取是否自动播放参数
 */
export function isAutoPlay() {
  return getUrlParams('_autoplay') !== '0';
}

/**
 * 是否进行静音播放
 */
export function isTryMutedAutoPlay() {
  return getUrlParams('_try_muted_autoplay') === '1';
}

/**
 * 从 URL 获取是否初始化静音参数
 */
export function notAutoMuted() {
  return getUrlParams('_muted') !== '1';
}

/**
 * 落地页，从中间层透传信息中获取基础信息
 */
export function getRoomBaseInfo() {
  if (!window.G_roomBaseInfo) {
    return null;
  }
  var _ref = window.G_roomBaseInfo || {},
    roomId = _ref.roomId,
    roomScreenshots = _ref.roomScreenshots,
    videoUrl = _ref.videoUrl,
    videoStreamStatus = _ref.videoStreamStatus,
    _ref$videoResolution = _ref.videoResolution,
    videoResolution = _ref$videoResolution === void 0 ? '' : _ref$videoResolution,
    _ref$resolution = _ref.resolution,
    resolution = _ref$resolution === void 0 ? 0 : _ref$resolution,
    _ref$multiResolutionV = _ref.multiResolutionVideoUrl,
    multiResolutionVideoUrl = _ref$multiResolutionV === void 0 ? [] : _ref$multiResolutionV,
    roomLineInfo = _ref.roomLineInfo,
    mStreamPkg = _ref.mStreamPkg,
    businessType = _ref.businessType,
    templateType = _ref.templateType,
    cdnFlag = _ref.cdnFlag,
    anchorId = _ref.anchorId,
    gameId = _ref.gameId;
  var _ref2 = roomScreenshots && roomScreenshots.find(function (item) {
      return item.key === 2;
    }) || {},
    url = _ref2.url;
  return {
    roomId: roomId,
    anchorId: anchorId,
    screenshot: url,
    videoUrl: videoUrl,
    videoStreamStatus: videoStreamStatus,
    videoResolution: videoResolution,
    resolution: resolution,
    multiResolutionVideoUrl: multiResolutionVideoUrl,
    cdnFlag: cdnFlag,
    businessType: businessType,
    templateType: templateType,
    gameId: gameId,
    roomLineInfo: _objectSpread(_objectSpread({}, roomLineInfo), {}, {
      mStreamPkg: mStreamPkg
    })
  };
}