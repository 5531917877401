import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import auth from '@client/common/services/auth';
export var INIT_ROOM_INFO = 'init_room_info';
export var INIT_ROOM_GIFT_LIST = 'init_room_gift_list';
export var UPDATE_ROOM_PACKAGE_LIST = 'update_room_package_list';
export var UPDATE_ACCOUNT_BALANCE = 'update_account_balance';
export var PLAYER_PAUSE = 'player_pause';
export var ANCHOR_ONLINE = 'anchor_online';
export var UPDATE_USER_INFO = 'update_user_info';
export var STREAM_UPDATE = 'stream_update';
export var UPDATE_MSG_WHITE_LIST = 'update_msg_white_list';
export var UPDATE_CURR_FANS_BARRAGE = 'update_curr_fans_barrage';
export var UPDATE_GIFT_PACK_NEW = 'update_gift_pack_new';
export var UPDATE_WEAR_BADGE_INFO = 'update_wear_badge_info';
export var UPDATE_BADGE_COUNT = 'update_badge_count';
export var INIT_ROOM_ACTIVITY_LIST = 'init_room_activity_list';
export var ANCHOR_PK_ON = 'anchor_pk_on';
export var UPDATE_LIVE_ROOM_VIEW_COUNT = 'update_live_room_view_count';
export var UPDATE_ANCHOR_FOLLOW_COUNT = 'update_anchor_follow_count';
export var UPDATE_ROOM_FOLLOW_STATUS = 'UPDATE_ROOM_FOLLOW_STATUS';
export var INIT_FACIALS_LIST = 'init_facials_list';
export var UPDATE_CHATROOM_POPOUT = 'update_chatroom_popout';
export var RESET_ROOM_STORE = 'reset_room_store';
export var UPDATE_FAN_GROUP_INFO = 'update_fan_group_info';
export var INIT_SUBSCRIBE_STATUS = 'init_subscribe_status';
export var ADD_GIFT_MSG = 'add_gift_msg';
export var REMOVE_GIFT_MSG = 'remove_gift_msg';
export var SET_MEETING_INFO = 'set_meeting_info';
export var UPDATE_SOCIAL_MEDIA_POPOUT = 'update_social_meida_popout';
export var SET_PAY_CHAT_LIST = 'set_pay_chat_list';
export var SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
export var SET_MEETING_EMOJI_LIST = 'SET_MEETING_EMOJI_LIST';
export var UPDATE_LINK_MIC_SEATS = 'UPDATE_LINK_MIC_SEATS';
export var UPDATE_LINK_MIC_HOST = 'UPDATE_LINK_MIC_HOST';
export var UPDATE_MGIFT_CONTRIBUTE_INFOS = 'UPDATE_MGIFT_CONTRIBUTE_INFOS';
export var UPDATE_SHOW_RECIPIENT = 'UPDATE_SHOW_RECIPIENT';
export var UPDATE_PENALTY_INFO = 'UPDATE_PENALTY_INFO';
export function initRoomInfo(roomInfo) {
  return _objectSpread({
    type: INIT_ROOM_INFO
  }, roomInfo);
}
export function resetRoomStore() {
  return {
    type: RESET_ROOM_STORE
  };
}
export function initRoomGiftList(giftList, giftTabList) {
  return {
    type: INIT_ROOM_GIFT_LIST,
    giftList: giftList,
    giftListLoaded: true,
    giftTabList: giftTabList
  };
}
export function updateAccountBalance(accountBalance) {
  return {
    type: UPDATE_ACCOUNT_BALANCE,
    accountBalance: accountBalance
  };
}
export function playerPause(playerState) {
  return {
    type: PLAYER_PAUSE,
    playerState: playerState
  };
}
export function anchorOnline(isOnline) {
  return {
    type: ANCHOR_ONLINE,
    isOnline: isOnline
  };
}
export var updateUserInfo = function updateUserInfo() {
  return function (dispatch) {
    return auth.getUserInfo().then(function (userInfo) {
      dispatch({
        type: UPDATE_USER_INFO,
        userInfo: userInfo
      });
      return userInfo;
    });
  };
};
export function streamUpdate(stream, streamId) {
  return {
    type: STREAM_UPDATE,
    stream: _defineProperty({}, streamId, stream)
  };
}
export function updateMsgWhiteList(data) {
  return _objectSpread({
    type: UPDATE_MSG_WHITE_LIST
  }, data);
}
export function updateCurrFansBarrage(barrage) {
  return _objectSpread({
    type: UPDATE_CURR_FANS_BARRAGE
  }, {
    currFansBarrage: barrage
  });
}
export function updateGiftPackNew(giftPackNew) {
  return {
    type: UPDATE_GIFT_PACK_NEW,
    giftPackNew: giftPackNew
  };
}
export function updateWearBadgeInfo(wearBadgeInfo) {
  return _objectSpread({
    type: UPDATE_WEAR_BADGE_INFO
  }, {
    wearBadgeInfo: wearBadgeInfo
  });
}
export function updateBadgeCount(badgeCount) {
  return _objectSpread({
    type: UPDATE_BADGE_COUNT
  }, {
    badgeCount: badgeCount
  });
}
export function initRoomActivityList(activityList) {
  var normalActivityList = activityList.normalActivityList,
    specialActivity = activityList.specialActivity;
  return {
    type: INIT_ROOM_ACTIVITY_LIST,
    normalActivityList: normalActivityList,
    specialActivity: specialActivity
  };
}
export function updateAnchorPkOn(anchorPKOn) {
  return {
    type: ANCHOR_PK_ON,
    anchorPKOn: anchorPKOn,
    anchorPKOnFetched: true
  };
}
export function updateLiveRoomViewCount(viewCount) {
  return {
    type: UPDATE_LIVE_ROOM_VIEW_COUNT,
    viewCount: viewCount
  };
}
export function updateAnchorFollowCount(followCount) {
  return {
    type: UPDATE_ANCHOR_FOLLOW_COUNT,
    followCount: followCount
  };
}
export function updateRoomFollowStatus(_ref) {
  var isFollow = _ref.isFollow,
    gotFollowed = _ref.gotFollowed;
  return {
    type: UPDATE_ROOM_FOLLOW_STATUS,
    isFollow: isFollow,
    gotFollowed: gotFollowed
  };
}
export function initFacialsList(facialsList) {
  return {
    type: INIT_FACIALS_LIST,
    facialsList: facialsList
  };
}
export function updateChatRoomPopout(chatRoomPopout) {
  return {
    type: UPDATE_CHATROOM_POPOUT,
    chatRoomPopout: chatRoomPopout
  };
}
export function updateFanGroupInfo(fanGroupInfo) {
  return {
    type: UPDATE_FAN_GROUP_INFO,
    fanGroupInfo: fanGroupInfo
  };
}
export function initVipSubscribeStatus(subscribeStatus) {
  return {
    type: INIT_SUBSCRIBE_STATUS,
    subscribeStatus: subscribeStatus
  };
}
export function addGiftMsg(message) {
  return {
    type: ADD_GIFT_MSG,
    message: message
  };
}
export function removeGiftMsg(message) {
  return {
    type: REMOVE_GIFT_MSG,
    message: message
  };
}
export function updatePackageList(packageList) {
  return {
    type: UPDATE_ROOM_PACKAGE_LIST,
    packageList: packageList
  };
}
export function setMeetingInfo(meetingInfo) {
  return {
    type: SET_MEETING_INFO,
    meetingInfo: meetingInfo
  };
}
export function updateSocialMediaPopout(socialMediaPopout) {
  return {
    type: UPDATE_SOCIAL_MEDIA_POPOUT,
    socialMediaPopout: socialMediaPopout
  };
}
export function setPayChatList(payChatList) {
  return {
    type: SET_PAY_CHAT_LIST,
    payChatList: payChatList
  };
}
export function setAnnouncement(announcement) {
  return {
    type: SET_ANNOUNCEMENT,
    announcement: announcement
  };
}
export function updateLinkMicSeats(linkMicSeats) {
  return {
    type: UPDATE_LINK_MIC_SEATS,
    linkMicSeats: linkMicSeats
  };
}
export function updateLinkMicHost(linkMicHost) {
  return {
    type: UPDATE_LINK_MIC_HOST,
    linkMicHost: linkMicHost
  };
}
export function updateShowRecipient(showRecipient) {
  return {
    type: UPDATE_SHOW_RECIPIENT,
    showRecipient: showRecipient
  };
}
export function updateMGiftContributeInfos(mGiftContributeInfos) {
  return {
    type: UPDATE_MGIFT_CONTRIBUTE_INFOS,
    mGiftContributeInfos: mGiftContributeInfos
  };
}
export function updatePenaltyInfo(penaltyInfo) {
  return {
    type: UPDATE_PENALTY_INFO,
    penaltyInfo: penaltyInfo
  };
}
export function addAccountBalanceTemp(type, amount) {
  return function (dispatch, getState) {
    var _balance$type;
    var state = getState();
    var balance = (state.home || state).room.accountBalance;
    var current = ((_balance$type = balance[type]) === null || _balance$type === void 0 ? void 0 : _balance$type.useableBalance) || 0;
    var newVal = "".concat(Number(current) + amount);
    dispatch(updateAccountBalance(_objectSpread(_objectSpread({}, balance), {}, _defineProperty({}, type, _objectSpread(_objectSpread({}, balance[type]), {}, {
      useableBalance: newVal
    })))));
  };
}