import Nimo from "../nimo";
import Taf from "@nimo-fed/taf-lib-wup";
var EPropPlayType = {
  EPROP_PLAY_NONE: 1,
  EPROP_PLAY_BOX: 2,
  EPROP_PLAY_LUCKY: 3,
  EPROP_PLAY_CHIP: 4,
  EPROP_PLAY_ROYAL: 5,
  EPROP_PLAY_FORTUNE: 6,
  EPROP_PLAY_STICKER: 7,
  EPROP_PLAY_HUDONGBOX: 8,
  EPROP_PLAY_WEEEKSTAR: 9,
  EPROP_PLAY_FAMILY: 10,
  EPROP_PLAY_SUPER_TREASURE: 11,
  EPROP_PLAY_VOICE: 12,
  EPROP_PLAY_ACTIVITY: 13,
  EPROP_PLAY_LOVE: 14,
  EPROP_PLAY_LUCKYBAG: 15,
  EPROP_PLAY_BLINDBOX: 16,
  EPROP_PLAY_GUARDIAN: 17,
  EPROP_PLAY_UPGRADE: 18,
  EPROP_PLAY_RAMDOM_EFFECT: 19,
  EPROP_PLAY_SVIP: 20,
  EPROP_PLAY_HUNTER: 21,
  EPROP_PLAY_SEND_HEART: 22,
  EPROP_PLAY_WIN_DIAMOND: 23,
  EPROP_PLAY_AR_STICKER: 24,
  EPROP_PLAY_ANCHOR_TURNAMENT: 25,
  EPROP_PLAY_TEAM_PK: 26,
  EPROP_PLAY_1V1_PK: 27
};
Nimo.EPropPlayType = EPropPlayType;
export default EPropPlayType;